<template>
    <div>
        <LoginComponent></LoginComponent>
    </div>

</template>

<script>

    import LoginComponent from "../components/LoginComponent";

  export default {
    name: 'Login',
      components: {
          LoginComponent

      }

  }

</script>

<style scoped>

</style>
