<template>
    <b-container fluid class="b-container text-center">
        <b-row align-h="center" align-v="center" style="height: 100vh">
            <b-col cols="3" style="background-color: white">
                <img class="logo" src="@/assets/logo_dark.png" alt="" width="310px"><br>
                by <img class="mb-3 mt-3" src="https://www.erkoware.de/assets/img/logo.png" alt="" width="140" height="42">

                <b-form class="form-signin">

                    <h3 class="h3 mb-3 font-weight-normal">Bitte einloggen</h3>
                    <b-form-group
                            id="input-group-email"
                            label="Email Adresse:"
                            label-for="email"
                    >
                        <b-form-input
                                id="email"
                                v-model="username"
                                type="email"
                                required
                                @keyup.enter="login"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                            id="input-group-password"
                            label="Passwort:"
                            label-for="password"
                    >
                        <b-form-input
                                id="password"
                                v-model="password"
                                type="password"
                                required
                                @keyup.enter="login"
                        ></b-form-input>
                    </b-form-group>

<!--                    <p style="font-size: 22px; color: #f29814" v-if="statusText"> {{ statusText }} </p>-->

                    <b-alert :show="!!(statusText)" variant="warning" style="color: #292929">{{ statusText }}</b-alert>

                    <b-button @click="login" variant="secondary">Login</b-button>
                    <p class="mt-3 mb-3 text-muted">&copy; 2020 erkoware solutions GbR</p>
                </b-form>

            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'LoginComponent',
        props: [
          'title'
        ],
        mounted() {
        },
        methods: {
            login() {

                // For Testing Only!!!
                // if (this.username === 'DjDieter' && this.password === '1337') {
                //     localStorage.setItem('user', 'Diesistgeheim')
                //     this.$router.replace('/')
                // } else {
                //     this.statusText = 'Login Fehler!';
                // }


                this.$api.post('oauth/token', {
                    username: this.username,
                    password: this.password,
                    client_id: 'client_frontend',
                    grant_type: 'password'
                })
                    .catch(() => {
                        this.statusText = 'Email oder Passwort falsch';
                    })
                    .then((response) => {
                        localStorage.setItem('user', response.data.access_token)
                        this.$router.replace('/')
                    })
            }
        },
        data() {
            return {
                username: '',
                password: '',
                statusText: '',
                time: null
            }
        }
    }

</script>

<style scoped>

    .b-container {
        background: var(--primary);
        color: var(--primary);
        height: 100vh;
    }

    .logo {
        margin-top: 50px;
        margin-left: 25px;
    }

    .form-signin {
        width: 100%;
        max-width: 330px;
        /*padding: 15px;*/
        margin: auto;
     }

    #email {
        background-color: #f8f9fa;
        margin-top: 5px;
        color: #424b54;
        border: solid #66b588;
    }

    #password {
        background-color: #f8f9fa;
        margin: 5px 0 10px;
        color: #424b54;
        border: solid #66b588;
    }

    /*#login-button {*/
    /*    font-size: 22px;*/
    /*    !*background-image: linear-gradient(to right, #000428 0%, #004e92 51%, #000428 100%);*!*/
    /*    !*background-position: right center*!*/
    /*    --background: #66b588;*/
    /*    --background-activated: #9ce1bd;*/
    /*}*/

    /*.custom-font {*/
    /*    font-size: 22px;*/
    /*    color: #424b54;*/
    /*}*/

</style>